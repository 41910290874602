
import { Component, Vue } from "vue-property-decorator";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueNoty from "vuejs-noty";
import VueApexCharts from "vue-apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vuejs-noty/dist/vuejs-noty.css";
import DashboardLayout from "@/layout/DashboardLayout.vue";

Vue.component("apexchart", VueApexCharts);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueNoty, { timeout: 3000, theme: "bootstrap-v4" });
Vue.use(VueApexCharts);

@Component({
  components: { DashboardLayout },
})
export default class App extends Vue {}
