
import { Component, Prop, Vue } from "vue-property-decorator";
import { SniperPlatform } from "@/api/admin-api";

@Component
export default class SniperPlatformsTable extends Vue {
  @Prop() platforms!: SniperPlatform[];

  /**
   * Records containing a platformId as key and a boolean representing an "is edited" state.
   */
  platformsModifiedMap: Record<string, boolean> = {};

  calcButtonClass(platformId: string): string {
    const hasPlatformBeenEdited = this.platformsModifiedMap[platformId];

    return hasPlatformBeenEdited ? "success" : "outline-secondary";
  }

  isFormControlsDisabled(platformId: string): boolean {
    const hasPlatformBeenEdited = this.platformsModifiedMap[platformId];

    return !hasPlatformBeenEdited;
  }

  created(): void {
    this.platformsModifiedMap = this.platforms.reduce(
      (accumulator: Record<string, boolean>, platform: SniperPlatform) => {
        accumulator[platform.id] = false;
        return accumulator;
      },
      {}
    );
  }

  userChangedPlatform(platformId: string): void {
    this.platformsModifiedMap[platformId] = true;
  }

  userClickedSavePlatform(platform: SniperPlatform): void {
    this.$emit("user-clicked-save-platform", platform);
  }
}
