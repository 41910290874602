
import { Component, Vue } from "vue-property-decorator";
import { PlatformId } from "@/api/admin-api";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";
import PlatformPublisherSettingsForm from "@/components/platforms/PlatformPublisherSettingsForm.vue";

@Component({ components: { PlatformPublisherSettingsForm, PlatformsEditNav } })
export default class PlatformCreate extends Vue {
  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }
  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }
}
