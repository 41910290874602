
import { Component, Vue } from "vue-property-decorator";
import {
  getStatisticsByPlatformId,
  PlatformId,
  PlatformStatistics,
  PlatformStatisticsResponse,
} from "@/api/admin-api";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";
import PlatformStatisticsTable from "@/components/platforms/PlatformStatisticsTable.vue";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { AxiosError } from "axios";

@Component({
  components: { PlatformsEditNav, PlatformStatisticsTable, LoadingIndicator },
})
export default class PlatformsStatistics extends Vue {
  statistics: WebData<AxiosError, PlatformStatistics[]> = webNotAsked();

  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }

  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }

  created(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.statistics = webLoading();
    getStatisticsByPlatformId(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(response: PlatformStatisticsResponse) {
    this.statistics = webSuccess(response.statistics);
  }

  private loadingError(error: AxiosError) {
    this.statistics = webError(error);
  }
}
