
import { Vue, Component, Prop } from "vue-property-decorator";
import { PlatformId } from "@/api/admin-api";

export type ActiveTab =
  | "details"
  | "ratings"
  | "investments"
  | "statistics"
  | "features"
  | "assets"
  | "publisher-settings"
  | "api";

@Component
export default class PlatformsEditNav extends Vue {
  @Prop() platformId: PlatformId;
  @Prop() active: ActiveTab;

  isActive(testActive: ActiveTab) {
    return testActive === this.active;
  }
}
