
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import {
  LOADING,
  SUCCESS,
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  CollateralQualifier,
  createPlatformInvestments,
  CreatePlatformInvestmentsRequest,
  Currency,
  getInvestmentsByPlatformId,
  InvestingIntoQualifier,
  InvestmentStructureQualifier,
  OriginatorTypeQualifier,
  PlatformId,
  PlatformInvestments,
  PlatformInvestmentsId,
  updatePlatformInvestments,
  UpdatePlatformInvestmentsRequest,
} from "@/api/admin-api";
import { whenAppIsInitialized } from "@/service/app-init-service";

interface FormData {
  id: PlatformInvestmentsId;
  investmentStructures: InvestmentStructureQualifier[];
  investingInto: InvestingIntoQualifier[];
  originatorTypes: OriginatorTypeQualifier[];
  interestRatesLower: string | null;
  interestRatesUpper: string | null;
  numberOfCountries: string | null;
  numberOfOriginators: string | null;
  currencies: Currency[];
  minimumInvestment: string | null;
  minimumInvestmentCurrency: Currency;
  collaterals: CollateralQualifier[];
  maximumLoanToValue: string | null;
}

type FormMethod = "create" | "update";

@Component({ components: { LoadingIndicator } })
export default class PlatformInvestmentsForm extends Vue {
  private readonly CREATE_METHOD: FormMethod = "create";
  private readonly UPDATE_METHOD: FormMethod = "update";
  private readonly NOT_FOUND_STATUS = 404;
  private readonly VALIDATION_ERROR_STATUS_CODE = 422;

  method: FormMethod = this.UPDATE_METHOD;

  @Prop({ required: false }) platformId?: PlatformId;
  investments: WebData<AxiosError, PlatformInvestments> = webNotAsked();

  show = true;
  isFormControlsDisabled = true;
  formData: FormData = PlatformInvestmentsForm.emptyFormData();

  async created() {
    await whenAppIsInitialized;
    await this.fetchData();
  }

  get isFormDisabled() {
    return this.investments.kind === LOADING;
  }

  get isFieldUpdateDisabled() {
    return this.method === "update";
  }

  get investmentStructureQualifiers() {
    return this.$store.getters.getConstants.investmentStructures
      .slice()
      .map(({ name }) => name);
  }

  get investingIntoQualifiers() {
    return this.$store.getters.getConstants.investingInto
      .slice()
      .map(({ name }) => name);
  }

  get originatorTypeQualifiers() {
    return this.$store.getters.getConstants.originatorTypes
      .slice()
      .map(({ name }) => name);
  }

  get collateralQualifiers() {
    return this.$store.getters.getConstants.collaterals
      .slice()
      .map(({ name }) => name);
  }

  get currencies() {
    return this.$store.getters.getConstants.currencies;
  }

  async fetchData() {
    this.investments = webLoading();
    return getInvestmentsByPlatformId(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(investments: PlatformInvestments): PlatformId {
    this.method = this.UPDATE_METHOD;
    this.isFormControlsDisabled = true;
    this.formData = PlatformInvestmentsForm.webDataToFormData(investments);
    this.investments = webSuccess(investments);
    return investments.id;
  }

  private loadingSuccessCreate() {
    this.$noty.success("The platform investments have been created");
  }

  private loadingSuccessUpdate(investments: PlatformInvestments) {
    this.loadingSuccess(investments);
    this.$noty.success("The platform investments have been updated");
  }

  private loadingError(error: AxiosError) {
    if (error.response.status === this.NOT_FOUND_STATUS) {
      // no investments have been saved in api. Thus, need to create one.
      this.method = this.CREATE_METHOD;
      // note: using notAsked here for being lazy to implement a better solution
      this.investments = webNotAsked();
      return;
    }
    this.investments = webError(error);
    this.$noty.error("Failure to load platform investments data");
  }

  private loadingErrorSave(error: AxiosError) {
    if (this.isValidationError(error)) {
      this.investments = webError(error.response.data.error);
      this.$noty.error("Failed to validate platform investments data");
      return;
    }
    this.investments = webError(error);
    this.$noty.error("Failure to save platform investments data");
  }

  private isValidationError(error: AxiosError) {
    return error?.response?.status === this.VALIDATION_ERROR_STATUS_CODE;
  }

  onChange() {
    this.isFormControlsDisabled = false;
  }

  onSubmit() {
    const { formData } = this;

    if (this.method === this.CREATE_METHOD) {
      const payload: CreatePlatformInvestmentsRequest = {
        investmentStructures: formData.investmentStructures,
        investingInto: formData.investingInto,
        originatorTypes: formData.originatorTypes,
        interestRatesLower: parseFloat(formData.interestRatesLower),
        interestRatesUpper: parseFloat(formData.interestRatesUpper),
        numberOfCountries: parseInt(formData.numberOfCountries),
        numberOfOriginators: parseInt(formData.numberOfCountries),
        currencies: formData.currencies,
        minimumInvestment: parseInt(formData.minimumInvestment),
        minimumInvestmentCurrency: formData.minimumInvestmentCurrency,
        collaterals: formData.collaterals,
        maximumLoanToValue: parseFloat(formData.maximumLoanToValue),
      };

      createPlatformInvestments(this.platformId, payload)
        .then(this.loadingSuccessCreate)
        .catch(this.loadingErrorSave);
    } else if (this.method === this.UPDATE_METHOD) {
      const payload: UpdatePlatformInvestmentsRequest = {
        investmentStructures: formData.investmentStructures,
        investingInto: formData.investingInto,
        originatorTypes: formData.originatorTypes,
        interestRatesLower: parseFloat(formData.interestRatesLower),
        interestRatesUpper: parseFloat(formData.interestRatesUpper),
        numberOfCountries: parseInt(formData.numberOfCountries),
        numberOfOriginators: parseInt(formData.numberOfOriginators),
        currencies: formData.currencies,
        minimumInvestment: parseInt(formData.minimumInvestment),
        minimumInvestmentCurrency: formData.minimumInvestmentCurrency,
        collaterals: formData.collaterals,
        maximumLoanToValue: parseFloat(formData.maximumLoanToValue),
      };

      updatePlatformInvestments(this.platformId, this.formData.id, payload)
        .then(this.loadingSuccessUpdate)
        .catch(this.loadingErrorSave);
    }
  }

  onReset() {
    this.formData =
      this.investments.kind === SUCCESS
        ? PlatformInvestmentsForm.webDataToFormData(this.investments.data)
        : PlatformInvestmentsForm.emptyFormData();
    this.isFormControlsDisabled = true;

    // Trick to reset/clear native browser form validation state
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  private static webDataToFormData(investments: PlatformInvestments): FormData {
    return {
      id: investments.id,
      investmentStructures: investments.investmentStructures.map(
        ({ name }) => name
      ),
      investingInto: investments.investingInto.map(({ name }) => name),
      originatorTypes: investments.originatorTypes.map(({ name }) => name),
      interestRatesLower: investments.interestRatesLower?.toString(),
      interestRatesUpper: investments.interestRatesUpper?.toString(),
      numberOfCountries: investments.numberOfCountries?.toString(),
      numberOfOriginators: investments.numberOfOriginators?.toString(),
      currencies: investments.currencies,
      minimumInvestment: investments.minimumInvestment?.toString(),
      minimumInvestmentCurrency: investments.minimumInvestmentCurrency,
      collaterals: investments.collaterals.map(({ name }) => name),
      maximumLoanToValue: investments.maximumLoanToValue?.toString(),
    };
  }

  private static emptyFormData(): FormData {
    return {
      id: null,
      investmentStructures: [],
      investingInto: [],
      originatorTypes: [],
      interestRatesLower: null,
      interestRatesUpper: null,
      numberOfCountries: null,
      numberOfOriginators: null,
      currencies: [],
      minimumInvestment: null,
      minimumInvestmentCurrency: null,
      collaterals: [],
      maximumLoanToValue: null,
    };
  }
}
