
import { Component, Vue } from "vue-property-decorator";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { getSniperStatistics, SniperStatisticsResponse } from "@/api/admin-api";
import { AxiosError } from "axios";

const appUrl = process.env.VUE_APP_URL;

interface ChartData {
  options: any;
  series: Array<{ name: string; data: Array<{ x: string; y: number }> }>;
}

@Component
export default class Home extends Vue {
  sniperStatistics: WebData<AxiosError, SniperStatisticsResponse> =
    webNotAsked();

  get usersCountChart(): ChartData {
    let data;
    if (this.sniperStatistics.kind !== "success") {
      data = [];
    } else {
      data = this.sniperStatistics.data.entries.map(({ date, usersCount }) => ({
        x: date,
        y: usersCount,
      }));
    }

    return {
      options: {
        xaxis: {
          type: "category",
        },
      },
      series: [
        {
          name: "Users Count",
          data,
        },
      ],
    };
  }

  get alertsSentCountChart(): ChartData {
    let data;
    if (this.sniperStatistics.kind !== "success") {
      data = [];
    } else {
      data = this.sniperStatistics.data.entries.map(
        ({ date, alertsSentCount }) => ({
          x: date,
          y: alertsSentCount,
        })
      );
    }

    return {
      options: {
        xaxis: {
          type: "category",
        },
      },
      series: [
        {
          name: "Alerts Sent Count",
          data,
        },
      ],
    };
  }

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.sniperStatistics = webLoading();
    getSniperStatistics().then(this.loadingSuccess).catch(this.loadingError);
  }

  private loadingSuccess(response: SniperStatisticsResponse) {
    this.sniperStatistics = webSuccess(response);
  }

  private loadingError(error: AxiosError) {
    this.sniperStatistics = webError(error);
  }

  userDisabledAdminUserTracking(): void {
    if (!appUrl) {
      throw new Error(
        `Cannot redirect client to set admin cookie URL, because APP_URL environment variable not set – aborting. [appUrl=${appUrl}]`
      );
    }
    // Go to app, set cookie, return to admin. If that cookie is present in app client, no beyondp2p analytics
    // tracking will be send to servers.
    location.assign(`${appUrl}/api/analytics/set-admin-cookie`);
  }
}
