
import { Component, Vue } from "vue-property-decorator";
import PlatformFeaturesForm from "@/components/platforms/PlatformFeaturesForm.vue";
import { PlatformId } from "@/api/admin-api";

@Component({ components: { PlatformFeaturesForm } })
export default class PlatformFeaturesCreate extends Vue {
  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }
  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }
}
