import { Mutation } from "@/store/mutations";
import { getApiConstants, getPlatformsMap } from "@/api/admin-api";

export enum Action {
  APP_LOADS_CONSTANTS = "APP_LOADS_CONSTANTS",
  APP_LOADS_PLATFORMS_MAP = "APP_LOADS_PLATFORMS_MAP",
}

export default {
  [Action.APP_LOADS_CONSTANTS]: ({ commit }) => {
    return getApiConstants().then((constants) => {
      commit(Mutation.SERVER_SENT_CONSTANTS, constants);
    });
  },
  [Action.APP_LOADS_PLATFORMS_MAP]: ({ commit }) => {
    return getPlatformsMap().then((platformsMap) => {
      commit(Mutation.SERVER_SENT_PLATFORMS_MAP, platformsMap);
    });
  },
};
