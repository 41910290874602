
import { Component, Vue } from "vue-property-decorator";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { AxiosError } from "axios";
import {
  getTelegramBotStatistics,
  TelegramBotStatisticsResponse,
} from "@/api/admin-api";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import TelegramBotStatistics from "@/components/telegrambot/TelegramBotStatistics.vue";
import TelegramBotMessageForm from "@/components/telegrambot/TelegramBotMessageForm.vue";

@Component({
  components: {
    TelegramBotMessageForm,
    TelegramBotStatistics,
    LoadingIndicator,
  },
})
export default class Bot extends Vue {
  statistics: WebData<AxiosError, TelegramBotStatisticsResponse> =
    webNotAsked();

  created(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.statistics = webLoading();
    getTelegramBotStatistics()
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(response: TelegramBotStatisticsResponse) {
    this.statistics = webSuccess(response);
  }

  private loadingError(error: AxiosError) {
    this.statistics = webError(error);
  }
}
