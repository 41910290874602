
import { Component, Vue } from "vue-property-decorator";
import {
  getLogoByPlatformId,
  PlatformAssetLogo,
  PlatformId,
  uploadLogoForPlatform,
} from "@/api/admin-api";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { AxiosError } from "axios";

@Component({
  components: { PlatformsEditNav, LoadingIndicator },
})
export default class PlatformsAssets extends Vue {
  logo: WebData<AxiosError, PlatformAssetLogo> = webNotAsked();
  logoFile = null;

  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }
  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.logo = webLoading();
    return getLogoByPlatformId(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(response: PlatformAssetLogo) {
    this.logo = webSuccess(response);
    this.logoFile = null;
  }

  private loadingError(error: AxiosError) {
    this.logo = webError(error);
    this.logoFile = null;
  }

  async onSubmitLogo() {
    if (this.logoFile == null) {
      this.$noty.error("Must select logo file before upload.");
      return;
    }

    try {
      const uploadedLogo = await uploadLogoForPlatform(
        this.platformId,
        this.logoFile
      );
      this.logo = webSuccess(uploadedLogo);
      this.$noty.success("Successfully uploaded platform logo");
    } catch (error) {
      this.logo = webError(error);
      this.$noty.error("Failure to upload platform logo");
    } finally {
      this.logoFile = null;
    }
  }
}
