
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import {
  LOADING,
  SUCCESS,
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  AffiliatePartner,
  createPlatformPublisherSettings,
  CreatePlatformPublisherSettingsRequest,
  getPublisherSettingsByPlatformId,
  MIGRATION_AFFILIATE_PARTNER,
  PlatformId,
  PlatformPublisherSettings,
  PlatformPublisherSettingsId,
  updatePlatformPublisherSettings,
  UpdatePlatformPublisherSettingsRequest,
} from "@/api/admin-api";
import { whenAppIsInitialized } from "@/service/app-init-service";

interface FormData {
  id: PlatformPublisherSettingsId;
  platformId: PlatformId;
  affiliatePartner: AffiliatePartner;
  affiliateLink: string;
}

type FormMethod = "create" | "update";

@Component({ components: { LoadingIndicator } })
export default class PlatformPublisherSettingsForm extends Vue {
  private CREATE_METHOD: FormMethod = "create";
  private UPDATE_METHOD: FormMethod = "update";
  private VALIDATION_ERROR_STATUS_CODE = 422;
  method: FormMethod = this.UPDATE_METHOD;

  @Prop({ required: true }) platformId: PlatformId;

  publisherSettings: WebData<AxiosError, PlatformPublisherSettings> =
    webNotAsked();

  show = true;
  isFormControlsDisabled = true;
  formData: FormData = PlatformPublisherSettingsForm.emptyFormData();

  async created() {
    await whenAppIsInitialized;
    await this.fetchData();

    if (this.formData.id != null) {
      this.method = this.UPDATE_METHOD;
    } else {
      this.method = this.CREATE_METHOD;
    }
  }

  get isFormDisabled() {
    return this.publisherSettings.kind === LOADING;
  }

  get isFieldUpdateDisabled() {
    return this.method === "update";
  }

  get affiliatePartners() {
    return this.$store.getters.getConstants.affiliatePartners
      .slice()
      .map((partner: AffiliatePartner) => ({
        value: partner,
        text: partner,
        disabled: partner === MIGRATION_AFFILIATE_PARTNER,
      }));
  }

  async fetchData() {
    this.publisherSettings = webLoading();
    return getPublisherSettingsByPlatformId(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(
    publisherSettings: PlatformPublisherSettings
  ): PlatformPublisherSettingsId {
    this.isFormControlsDisabled = true;
    this.formData =
      PlatformPublisherSettingsForm.webDataToFormData(publisherSettings);
    this.publisherSettings = webSuccess(publisherSettings);
    return publisherSettings.id;
  }

  private loadingSuccessCreate(publisherSettings: PlatformPublisherSettings) {
    this.formData.id = this.loadingSuccess(publisherSettings);
    this.method = this.UPDATE_METHOD;
    this.$noty.success("The platform publisher settings have been created");
  }

  private loadingSuccessUpdate(publisherSettings: PlatformPublisherSettings) {
    this.loadingSuccess(publisherSettings);
    this.$noty.success("The platform publisher settings have been updated");
  }

  private loadingError(error: AxiosError) {
    this.publisherSettings = webError(error);
    this.$noty.error("Failure to load platform publisher settings data");
  }

  private loadingErrorSave(error: AxiosError) {
    if (this.isValidationError(error)) {
      this.publisherSettings = webError(error.response.data.error);
      this.$noty.error("Failed to validate platform publisher settings data");
      return;
    }
    this.publisherSettings = webError(error);
    this.$noty.error("Failure to save platform publisher settings data");
  }

  private isValidationError(error: AxiosError) {
    return error?.response?.status === this.VALIDATION_ERROR_STATUS_CODE;
  }

  onChange() {
    this.isFormControlsDisabled = false;
  }

  onSubmit() {
    const { formData } = this;

    if (this.method === this.CREATE_METHOD) {
      const payload: CreatePlatformPublisherSettingsRequest = formData;
      createPlatformPublisherSettings(this.platformId, payload)
        .then(this.loadingSuccessCreate)
        .catch(this.loadingErrorSave);
    } else if (this.method === this.UPDATE_METHOD) {
      const { affiliatePartner, affiliateLink } = formData;
      const payload: UpdatePlatformPublisherSettingsRequest = {
        affiliatePartner,
        affiliateLink,
      };
      updatePlatformPublisherSettings(this.platformId, payload)
        .then(this.loadingSuccessUpdate)
        .catch(this.loadingErrorSave);
    }
  }

  onReset() {
    this.formData =
      this.publisherSettings.kind === SUCCESS
        ? PlatformPublisherSettingsForm.webDataToFormData(
            this.publisherSettings.data
          )
        : PlatformPublisherSettingsForm.emptyFormData();
    this.isFormControlsDisabled = true;

    // Trick to reset/clear native browser form validation state
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  private static webDataToFormData(
    platformSettings: PlatformPublisherSettings
  ): FormData {
    return {
      id: platformSettings.id,
      platformId: platformSettings.platformId,
      affiliatePartner: platformSettings.affiliatePartner,
      affiliateLink: platformSettings.affiliateLink,
    };
  }

  private static emptyFormData(): FormData {
    return {
      id: null,
      platformId: null,
      affiliatePartner: null,
      affiliateLink: "",
    };
  }
}
