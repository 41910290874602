
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  AvailableFeature,
  NotAvailableFeature,
  PlatformFeatureId,
  PlatformId,
} from "@/api/admin-api";

export interface DeleteFeatureEventPayload {
  platformId: PlatformId;
  featureId: PlatformFeatureId;
}

@Component
export default class PlatformFeaturesTable extends Vue {
  @Prop() platformId!: PlatformId;
  @Prop() availableFeatures!: AvailableFeature[];
  @Prop() notAvailableFeatures!: NotAvailableFeature[];

  get availableFeaturesSorted() {
    return this.availableFeatures.sort(
      (a: AvailableFeature, b: AvailableFeature) => a.name.localeCompare(b.name)
    );
  }

  get notAvailableFeaturesSorted() {
    return this.notAvailableFeatures.sort(
      (a: NotAvailableFeature, b: NotAvailableFeature) =>
        a.name.localeCompare(b.name)
    );
  }

  askDeleteFeature(platformId: PlatformId, featureId: PlatformFeatureId) {
    const answer = confirm("Do you really want to delete the feature?");

    if (answer !== true) {
      return;
    }

    const deleteFeatureEventPayload: DeleteFeatureEventPayload = {
      platformId,
      featureId,
    };

    this.$emit("user-requested-delete-feature", deleteFeatureEventPayload);
  }
}
