
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import {
  LOADING,
  SUCCESS,
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  AvailableFeature,
  createPlatformFeature,
  CreatePlatformFeatureRequest,
  FeatureTypeQualifier,
  getFeatureByIds,
  PlatformFeatureId,
  PlatformId,
  updatePlatformFeature,
  UpdatePlatformFeatureRequest,
} from "@/api/admin-api";
import { whenAppIsInitialized } from "@/service/app-init-service";

interface FormData {
  id: PlatformFeatureId;
  name: FeatureTypeQualifier;
  description: string | null;
  names: string[];
}

type FormMethod = "create" | "update";

@Component({ components: { LoadingIndicator } })
export default class PlatformFeaturesForm extends Vue {
  private CREATE_METHOD: FormMethod = "create";
  private UPDATE_METHOD: FormMethod = "update";
  private VALIDATION_ERROR_STATUS_CODE = 422;
  method: FormMethod = this.UPDATE_METHOD;

  @Prop() readonly platformId!: PlatformId;
  @Prop({ required: false }) readonly featureId?: PlatformFeatureId;
  feature: WebData<AxiosError, AvailableFeature> = webNotAsked();

  show = true;
  /**
   * For features form, always enable form controls. We want to create available features
   * without providing any change to the form inputs.
   */
  isFormControlsDisabled = false;
  formData: FormData = this.emptyFormData();

  async created() {
    await whenAppIsInitialized;

    if (this.platformId != null && this.featureId != null) {
      await this.setupUpdateForm();
    } else {
      this.setupCreateForm();
    }
  }

  private async setupUpdateForm() {
    this.method = this.UPDATE_METHOD;
    await this.fetchData();
  }

  private setupCreateForm() {
    if (this.featureTypeParam == null) {
      this.$noty.error("featureTypeParam cannot be null");
      throw new Error("featureTypeParam cannot be null");
    }

    const featureType = this.featureTypeNames.find(
      (name) => name === this.featureTypeParam
    );

    if (!featureType) {
      this.$noty.error("featureTypeParam cannot be found in featureTypes");
      throw new Error("featureTypeParam cannot be found in featureTypes");
    }

    this.method = this.CREATE_METHOD;
    this.formData.name = featureType;
  }

  get isFormDisabled() {
    return this.feature.kind === LOADING;
  }

  get featureTypeParam(): FeatureTypeQualifier {
    // hack to make comply with interface
    return this.$route.query.type?.toString();
  }

  get featureTypeNames(): FeatureTypeQualifier[] {
    return this.$store.getters.getConstants.featureTypes
      .slice()
      .map(({ name }) => name);
  }

  async fetchData() {
    this.feature = webLoading();
    return getFeatureByIds(this.platformId, this.featureId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(feature: AvailableFeature): PlatformFeatureId {
    // this.isFormControlsDisabled = true;
    this.formData = this.webDataToFormData(feature);
    this.feature = webSuccess(feature);
    return feature.id;
  }

  private loadingSuccessCreate(feature: AvailableFeature) {
    const featureId = this.loadingSuccess(feature);
    this.$router.push(`/platforms/${this.platformId}/features/${featureId}`);
    this.$noty.success("The platform feature has been created");
  }

  private loadingSuccessUpdate(feature: AvailableFeature) {
    this.loadingSuccess(feature);
    this.$noty.success("The platform feature have been updated");
  }

  private loadingError(error: AxiosError) {
    this.feature = webError(error);
    this.$noty.error("Failure to load platform feature data");
  }

  private loadingErrorSave(error: AxiosError) {
    if (this.isValidationError(error)) {
      this.feature = webError(error.response.data.error);
      this.$noty.error("Failed to validate platform feature data");
      return;
    }
    this.feature = webError(error);
    this.$noty.error("Failure to save platform feature data");
  }

  private isValidationError(error: AxiosError) {
    return error?.response?.status === this.VALIDATION_ERROR_STATUS_CODE;
  }

  onChange() {
    // this.isFormControlsDisabled = false;
  }

  onSubmit() {
    const { formData } = this;

    if (this.method === this.CREATE_METHOD) {
      const payload: CreatePlatformFeatureRequest = {
        type: formData.name,
        description: formData.description,
        names: formData.names,
      };
      createPlatformFeature(this.platformId, payload)
        .then(this.loadingSuccessCreate)
        .catch(this.loadingErrorSave);
    } else if (this.method === this.UPDATE_METHOD) {
      const { id } = formData;
      const payload: UpdatePlatformFeatureRequest = {
        description: formData.description,
        names: formData.names,
      };
      updatePlatformFeature(this.platformId, id, payload)
        .then(this.loadingSuccessUpdate)
        .catch(this.loadingErrorSave);
    }
  }

  onReset() {
    this.formData =
      this.feature.kind === SUCCESS
        ? this.webDataToFormData(this.feature.data)
        : this.emptyFormData();
    // this.isFormControlsDisabled = true;

    // Trick to reset/clear native browser form validation state
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  private webDataToFormData(feature: AvailableFeature): FormData {
    return {
      id: feature.id,
      name: feature.name,
      description: feature.description,
      names: feature.names,
    };
  }

  private emptyFormData(): FormData {
    return {
      id: null,
      name: null,
      description: null,
      names: [],
    };
  }
}
