
import { Component, Vue } from "vue-property-decorator";
import {
  PlatformId,
  PlatformFeaturesResponse,
  getFeaturesByPlatformId,
  deletePlatformFeature,
} from "@/api/admin-api";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import PlatformFeaturesTable, {
  DeleteFeatureEventPayload,
} from "@/components/platforms/PlatformFeaturesTable.vue";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { AxiosError } from "axios";

@Component({
  components: { PlatformsEditNav, LoadingIndicator, PlatformFeaturesTable },
})
export default class PlatformsFeatures extends Vue {
  features: WebData<AxiosError, PlatformFeaturesResponse> = webNotAsked();

  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }
  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.features = webLoading();
    getFeaturesByPlatformId(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(response: PlatformFeaturesResponse) {
    this.features = webSuccess(response);
  }

  private loadingError(error: AxiosError) {
    this.features = webError(error);
  }

  async deleteFeature(event: DeleteFeatureEventPayload): Promise<void> {
    return deletePlatformFeature(event.platformId, event.featureId)
      .catch((error) =>
        this.$noty.error(`Failed to delete platform feature (error: ${error})`)
      )
      .then(this.fetchData)
      .then(() => this.$noty.success("Successfully deleted platform feature"));
  }
}
