
import { Component, Vue } from "vue-property-decorator";
import {
  createExternalPlatformRating,
  CreateExternalPlatformRatingRequest,
  createOwnPlatformRating,
  CreateOwnPlatformRatingRequest,
  deleteExternalPlatformRating,
  deleteOwnPlatformRating,
  ExternalPlatformRating,
  getRatingByPlatformId,
  OwnPlatformRating,
  PlatformId,
  PlatformRating,
  updateExternalPlatformRating,
  UpdateExternalPlatformRatingRequest,
  updateOwnPlatformRating,
  UpdateOwnPlatformRatingRequest,
} from "@/api/admin-api";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";

interface OwnPlatformRatingForm {
  id: string;
  stars: number;
}

interface ExternalPlatformRatingForm {
  id: string;
  url: string;
  stars: number;
}

@Component({
  components: { PlatformsEditNav },
})
export default class PlatformsRatings extends Vue {
  private isInitiallyLoaded = false;
  private isLoading = false;
  private rating: PlatformRating | null;
  private ownRatingForm: OwnPlatformRatingForm =
    PlatformsRatings.emptyOwnRatingForm();

  private newExternalRatingForm: ExternalPlatformRatingForm =
    PlatformsRatings.emptyExternalRatingForm();

  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }

  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }

  async created() {
    await this.fetchRating();
    this.isInitiallyLoaded = true;
  }

  async fetchRating() {
    this.isLoading = true;
    return getRatingByPlatformId(this.platformId)
      .then((rating) => {
        const externalRatingEntries = rating.externalPlatformRatings.map(
          (externalRating) => ({
            ...externalRating,
            hasChanged: false,
          })
        );

        this.rating = {
          ...rating,
          externalPlatformRatings: externalRatingEntries,
        };

        if (rating.ownPlatformRating != null) {
          this.ownRatingForm = {
            id: rating.ownPlatformRating.id,
            stars: rating.ownPlatformRating.stars,
          };
        }
      })
      .catch(() => this.$noty.error("Failed to load platform rating"))
      .finally(() => (this.isLoading = false));
  }

  async userSubmittedOwnPlatformRatingForm() {
    let changedOwnRating: OwnPlatformRating;
    if (this.ownRatingForm.id == null) {
      const request: CreateOwnPlatformRatingRequest = {
        stars: this.ownRatingForm.stars,
      };
      try {
        changedOwnRating = await createOwnPlatformRating(
          this.platformId,
          request
        );
        this.$noty.success("Created own platform rating");
      } catch (e) {
        this.$noty.error("Failed to create own platform rating: " + e);
      }
    } else {
      const request: UpdateOwnPlatformRatingRequest = {
        stars: this.ownRatingForm.stars,
      };
      try {
        changedOwnRating = await updateOwnPlatformRating(
          this.platformId,
          request
        );
        this.$noty.success("Updated own platform rating");
      } catch (e) {
        this.$noty.error("Failed to update own platform rating: " + e);
      }
    }

    this.ownRatingForm = {
      id: changedOwnRating.id,
      stars: changedOwnRating.stars,
    };

    await this.fetchRating();
  }

  async userClickedDeleteOwnRatingButton() {
    if (confirm("Do you really want to delete the own platform rating?")) {
      try {
        await deleteOwnPlatformRating(this.platformId);
        this.ownRatingForm = PlatformsRatings.emptyOwnRatingForm();
        this.$noty.success("Deleted own platform rating");
        await this.fetchRating();
      } catch (e) {
        this.$noty.error("Failed to delete own platform rating: " + e);
      }
    }
  }

  async userSubmittedNewExternalPlatformRatingForm() {
    const request: CreateExternalPlatformRatingRequest = {
      url: this.newExternalRatingForm.url,
      stars: this.newExternalRatingForm.stars,
    };
    try {
      await createExternalPlatformRating(this.platformId, request);
      this.$noty.success("Created external platform rating");
    } catch (e) {
      this.$noty.error("Failed to create external platform rating: " + e);
    }

    // reset
    this.newExternalRatingForm = PlatformsRatings.emptyExternalRatingForm();

    await this.fetchRating();
  }

  async userClickedUpdateExternalPlatformRatingButton(
    rating: ExternalPlatformRating
  ) {
    try {
      const updateRequest: UpdateExternalPlatformRatingRequest = {
        url: rating.url,
        stars: rating.stars,
      };
      await updateExternalPlatformRating(
        this.platformId,
        rating.id,
        updateRequest
      );
      this.$noty.success("Updated external platform rating");
      await this.fetchRating();
    } catch (e) {
      this.$noty.error("Failed to update external platform rating: " + e);
    }
  }

  async userClickedDeleteExternalPlatformRatingButton(
    rating: ExternalPlatformRating
  ) {
    if (confirm("Do you really want to delete the external platform rating?")) {
      try {
        await deleteExternalPlatformRating(this.platformId, rating.id);
        this.$noty.success("Deleted external platform rating");
        await this.fetchRating();
      } catch (e) {
        this.$noty.error("Failed to delete external platform rating: " + e);
      }
    }
  }

  private static emptyOwnRatingForm() {
    return {
      id: null,
      stars: null,
    };
  }

  private static emptyExternalRatingForm() {
    return {
      id: null,
      url: null,
      stars: null,
    };
  }
}
