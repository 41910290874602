
import { Component, Vue } from "vue-property-decorator";
import { logoutFromKeycloak } from "@/service/keycloak-service";

@Component
export default class Navbar extends Vue {
  logout(): void {
    logoutFromKeycloak();
  }
}
