
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import {
  LOADING,
  SUCCESS,
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  createPlatformStatistics,
  CreatePlatformStatisticsRequest,
  getStatisticsByIds,
  PlatformId,
  PlatformStatistics,
  PlatformStatisticsId,
  updatePlatformStatistics,
  UpdatePlatformStatisticsRequest,
} from "@/api/admin-api";
import { whenAppIsInitialized } from "@/service/app-init-service";

interface FormData {
  id: PlatformStatisticsId;
  loansTotalFunded: string | null;
  loansOutstanding: string | null;
  loansCurrent: string | null;
  loansDaysLate: string | null;
  loansDaysLate1To15: string | null;
  loansDaysLate16To30: string | null;
  loansDaysLate31To60: string | null;
  loansDaysLate60Plus: string | null;
  loansDefaultedInRecovery: string | null;
  loansDefaultedRecovered: string | null;
  loansDefaultedWrittenOff: string | null;
  investors: string | null;
  averageNetAnnualReturn: string | null;
  averageAmountPerInvestor: string | null;
  principalReturned: string | null;
  interestEarned: string | null;
  lateFeesEarned: string | null;
}

type FormMethod = "create" | "update";

@Component({ components: { LoadingIndicator } })
export default class PlatformStatisticsForm extends Vue {
  private CREATE_METHOD: FormMethod = "create";
  private UPDATE_METHOD: FormMethod = "update";
  private VALIDATION_ERROR_STATUS_CODE = 422;
  method: FormMethod = this.UPDATE_METHOD;

  @Prop() platformId!: PlatformId;
  @Prop({ required: false }) statisticsId?: PlatformStatisticsId;
  statistics: WebData<AxiosError, PlatformStatistics> = webNotAsked();

  show = true;
  isFormControlsDisabled = true;
  formData: FormData = PlatformStatisticsForm.emptyFormData();

  async created() {
    await whenAppIsInitialized;

    if (this.platformId != null && this.statisticsId != null) {
      this.method = this.UPDATE_METHOD;
      await this.fetchData();
    } else {
      this.method = this.CREATE_METHOD;
    }
  }

  get isFormDisabled() {
    return this.statistics.kind === LOADING;
  }

  get currencies() {
    return this.$store.getters.getConstants.currencies;
  }

  async fetchData() {
    this.statistics = webLoading();
    return getStatisticsByIds(this.platformId, this.statisticsId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(statistics: PlatformStatistics): PlatformStatisticsId {
    this.isFormControlsDisabled = true;
    this.formData = PlatformStatisticsForm.webDataToFormData(statistics);
    this.statistics = webSuccess(statistics);
    return statistics.id;
  }

  private loadingSuccessCreate(statistics: PlatformStatistics) {
    const statisticsId = this.loadingSuccess(statistics);
    this.$router.push(
      `/platforms/${this.platformId}/statistics/${statisticsId}`
    );
    this.$noty.success("The platform statistics has been created");
  }

  private loadingSuccessUpdate(statistics: PlatformStatistics) {
    this.loadingSuccess(statistics);
    this.$noty.success("The platform statistics have been updated");
  }

  private loadingError(error: AxiosError) {
    this.statistics = webError(error);
    this.$noty.error("Failure to load platform statistics data");
  }

  private loadingErrorSave(error: AxiosError) {
    if (this.isValidationError(error)) {
      this.statistics = webError(error.response.data.error);
      this.$noty.error("Failed to validate platform statistics data");
      return;
    }
    this.statistics = webError(error);
    this.$noty.error("Failure to save platform statistics data");
  }

  private isValidationError(error: AxiosError) {
    return error?.response?.status === this.VALIDATION_ERROR_STATUS_CODE;
  }

  onChange() {
    this.isFormControlsDisabled = false;
  }

  onSubmit() {
    const { formData } = this;

    if (this.method === this.CREATE_METHOD) {
      const payload: CreatePlatformStatisticsRequest = {
        loansTotalFunded: parseInt(formData.loansTotalFunded),
        loansOutstanding: parseInt(formData.loansOutstanding),
        loansCurrent: parseInt(formData.loansCurrent),
        loansDaysLate: parseInt(formData.loansDaysLate),
        loansDaysLate1To15: parseInt(formData.loansDaysLate1To15),
        loansDaysLate16To30: parseInt(formData.loansDaysLate16To30),
        loansDaysLate31To60: parseInt(formData.loansDaysLate31To60),
        loansDaysLate60Plus: parseInt(formData.loansDaysLate60Plus),
        loansDefaultedInRecovery: parseInt(formData.loansDefaultedInRecovery),
        loansDefaultedRecovered: parseInt(formData.loansDefaultedRecovered),
        loansDefaultedWrittenOff: parseInt(formData.loansDefaultedWrittenOff),
        investors: parseInt(formData.investors),
        averageNetAnnualReturn: parseFloat(formData.averageNetAnnualReturn),
        averageAmountPerInvestor: parseInt(formData.averageAmountPerInvestor),
        principalReturned: parseInt(formData.principalReturned),
        interestEarned: parseInt(formData.interestEarned),
        lateFeesEarned: parseInt(formData.lateFeesEarned),
      };
      createPlatformStatistics(this.platformId, payload)
        .then(this.loadingSuccessCreate)
        .catch(this.loadingErrorSave);
    } else if (this.method === this.UPDATE_METHOD) {
      const { id } = formData;
      const payload: UpdatePlatformStatisticsRequest = {
        loansTotalFunded: parseInt(formData.loansTotalFunded),
        loansOutstanding: parseInt(formData.loansOutstanding),
        loansCurrent: parseInt(formData.loansCurrent),
        loansDaysLate: parseInt(formData.loansDaysLate),
        loansDaysLate1To15: parseInt(formData.loansDaysLate1To15),
        loansDaysLate16To30: parseInt(formData.loansDaysLate16To30),
        loansDaysLate31To60: parseInt(formData.loansDaysLate31To60),
        loansDaysLate60Plus: parseInt(formData.loansDaysLate60Plus),
        loansDefaultedInRecovery: parseInt(formData.loansDefaultedInRecovery),
        loansDefaultedRecovered: parseInt(formData.loansDefaultedRecovered),
        loansDefaultedWrittenOff: parseInt(formData.loansDefaultedWrittenOff),
        investors: parseInt(formData.investors),
        averageNetAnnualReturn: parseFloat(formData.averageNetAnnualReturn),
        averageAmountPerInvestor: parseInt(formData.averageAmountPerInvestor),
        principalReturned: parseInt(formData.principalReturned),
        interestEarned: parseInt(formData.interestEarned),
        lateFeesEarned: parseInt(formData.lateFeesEarned),
      };
      updatePlatformStatistics(this.platformId, id, payload)
        .then(this.loadingSuccessUpdate)
        .catch(this.loadingErrorSave);
    }
  }

  onReset() {
    this.formData =
      this.statistics.kind === SUCCESS
        ? PlatformStatisticsForm.webDataToFormData(this.statistics.data)
        : PlatformStatisticsForm.emptyFormData();
    this.isFormControlsDisabled = true;

    // Trick to reset/clear native browser form validation state
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  private static webDataToFormData(statistics: PlatformStatistics): FormData {
    return {
      id: statistics.id,
      loansTotalFunded: statistics.loansTotalFunded?.toString(),
      loansOutstanding: statistics.loansOutstanding?.toString(),
      loansCurrent: statistics.loansCurrent?.toString(),
      loansDaysLate: statistics.loansDaysLate?.toString(),
      loansDaysLate1To15: statistics.loansDaysLate1To15?.toString(),
      loansDaysLate16To30: statistics.loansDaysLate16To30?.toString(),
      loansDaysLate31To60: statistics.loansDaysLate31To60?.toString(),
      loansDaysLate60Plus: statistics.loansDaysLate60Plus?.toString(),
      loansDefaultedInRecovery: statistics.loansDefaultedInRecovery?.toString(),
      loansDefaultedRecovered: statistics.loansDefaultedRecovered?.toString(),
      loansDefaultedWrittenOff: statistics.loansDefaultedWrittenOff?.toString(),
      investors: statistics.investors?.toString(),
      averageNetAnnualReturn: statistics.averageNetAnnualReturn?.toString(),
      averageAmountPerInvestor: statistics.averageAmountPerInvestor?.toString(),
      principalReturned: statistics.principalReturned?.toString(),
      interestEarned: statistics.interestEarned?.toString(),
      lateFeesEarned: statistics.lateFeesEarned?.toString(),
    };
  }

  private static emptyFormData(): FormData {
    return {
      id: null,
      loansTotalFunded: null,
      loansOutstanding: null,
      loansCurrent: null,
      loansDaysLate: null,
      loansDaysLate1To15: null,
      loansDaysLate16To30: null,
      loansDaysLate31To60: null,
      loansDaysLate60Plus: null,
      loansDefaultedInRecovery: null,
      loansDefaultedRecovered: null,
      loansDefaultedWrittenOff: null,
      investors: null,
      averageNetAnnualReturn: null,
      averageAmountPerInvestor: null,
      principalReturned: null,
      interestEarned: null,
      lateFeesEarned: null,
    };
  }
}
