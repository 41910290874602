import { State } from "@/store/index";
import { ApiConstants, PlatformId, PlatformLookup } from "@/api/admin-api";

function isAuthenticated(state: State): boolean {
  return state.accessToken != null;
}

function getAccessToken(state: State): string | null {
  return state.accessToken;
}

function getConstants(state: State): ApiConstants {
  return state.constants;
}

function lookupPlatformById(state: State): (PlatformId) => PlatformLookup {
  return function (platformId: PlatformId) {
    return state.platformsMap[platformId];
  };
}

export default {
  isAuthenticated,
  getAccessToken,
  getConstants,
  lookupPlatformById,
};
