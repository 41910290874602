
import { Component, Vue } from "vue-property-decorator";
import { PlatformId, PlatformLookup } from "@/api/admin-api";
import PlatformForm from "@/components/platforms/PlatformForm.vue";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";
import PlatformInvestmentsForm from "@/components/platforms/PlatformInvestmentsForm.vue";

@Component({
  components: { PlatformInvestmentsForm, PlatformsEditNav, PlatformForm },
})
export default class PlatformEdit extends Vue {
  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }
  get platformName(): PlatformLookup {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }
}
