export const NOT_ASKED = "not-asked";
export interface NotAsked {
  kind: "not-asked";
}
export function webNotAsked(): NotAsked {
  return { kind: NOT_ASKED };
}

export const LOADING = "loading";
export interface Loading {
  kind: "loading";
}
export function webLoading(): Loading {
  return { kind: LOADING };
}

export const ERROR = "error";
export interface Error<E> {
  kind: "error";
  error: E;
}
export function webError<E>(error: E): Error<E> {
  return {
    kind: ERROR,
    error,
  };
}

export const SUCCESS = "success";
export interface Success<D> {
  kind: "success";
  data: D;
}
export function webSuccess<D>(data: D): Success<D> {
  return {
    kind: SUCCESS,
    data,
  };
}

export type WebData<E, D> = NotAsked | Loading | Error<E> | Success<D>;
