
import { Component, Vue } from "vue-property-decorator";
import PlatformStatisticsForm from "@/components/platforms/PlatformStatisticsForm.vue";
import { PlatformId, PlatformStatisticsId } from "@/api/admin-api";

@Component({ components: { PlatformStatisticsForm } })
export default class PlatformStatisticsCreate extends Vue {
  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }
  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }
  get statisticsId(): PlatformStatisticsId {
    return this.$route.params.statisticsId;
  }
}
