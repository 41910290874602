import Vue from "vue";
import Vuex from "vuex";
import actions from "@/store/actions";
import getters from "@/store/getters";
import { getAccessTokenFromStorage } from "@/service/auth";
import mutations from "@/store/mutations";
import {
  ApiConstants,
  EMPTY_API_CONSTANTS,
  PlatformsMap,
} from "@/api/admin-api";

Vue.use(Vuex);

export interface State {
  constants: ApiConstants;
  accessToken: string | null;
  platformsMap: PlatformsMap;
}

export default new Vuex.Store({
  state: {
    constants: EMPTY_API_CONSTANTS,
    accessToken: getAccessTokenFromStorage(),
    platformsMap: {},
  },
  mutations,
  actions,
  getters,
  modules: {},
});
