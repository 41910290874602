
import { Component, Vue } from "vue-property-decorator";
import {
  getPlatformsTable,
  PlatformsTableResponse,
  PlatformsTableRow,
} from "@/api/admin-api";
import { AxiosError } from "axios";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import PlatformsTable from "@/components/platforms/PlatformsTable.vue";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";

@Component({ components: { PlatformsTable, LoadingIndicator } })
export default class Platforms extends Vue {
  platforms: WebData<AxiosError, PlatformsTableRow[]> = webNotAsked();

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.platforms = webLoading();
    getPlatformsTable().then(this.loadingSuccess).catch(this.loadingError);
  }

  private loadingSuccess(response: PlatformsTableResponse) {
    this.platforms = webSuccess(
      response.platforms.sort((a: PlatformsTableRow, b: PlatformsTableRow) =>
        a.name.localeCompare(b.name)
      )
    );
  }

  private loadingError(error: AxiosError) {
    this.platforms = webError(error);
  }
}
