import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { authenticateWithKeycloak } from "./service/keycloak-service";

Vue.config.productionTip = false;

authenticateWithKeycloak().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

declare module "vue/types/vue" {
  interface VueConstructor {
    // eslint-disable-next-line
    noty: any;
  }

  interface Vue {
    // eslint-disable-next-line
    $noty: any;
  }
}
