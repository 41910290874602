import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Platforms from "@/views/platforms/Platforms.vue";
import PlatformCreate from "@/views/platforms/PlatformCreate.vue";
import PlatformEdit from "@/views/platforms/PlatformEdit.vue";
import PlatformRatings from "@/views/platforms/ratings/PlatformRatings.vue";
import PlatformInvestments from "@/views/platforms/investments/PlatformInvestments.vue";
import PlatformStatistics from "@/views/platforms/statistics/PlatformStatistics.vue";
import PlatformStatisticsCreate from "@/views/platforms/statistics/PlatformStatisticsCreate.vue";
import PlatformStatisticsEdit from "@/views/platforms/statistics/PlatformStatisticsEdit.vue";
import PlatformFeatures from "@/views/platforms/features/PlatformFeatures.vue";
import PlatformFeaturesCreate from "@/views/platforms/features/PlatformFeaturesCreate.vue";
import PlatformFeaturesEdit from "@/views/platforms/features/PlatformFeaturesEdit.vue";
import PlatformAssets from "@/views/platforms/assets/PlatformAssets.vue";
import PlatformPublisherSettings from "@/views/platforms/publishersettings/PlatformPublisherSettings.vue";
import Publishers from "@/views/publishers/Publishers.vue";
import TelegramBot from "@/views/telegrambot/TelegramBot.vue";
import Data from "@/views/data/Data.vue";
import PlatformApi from "@/views/platforms/api/PlatformApi.vue";
import Sniper from "@/views/sniper/Sniper.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // platforms
  {
    path: "/platforms",
    name: "Platforms",
    component: Platforms,
  },
  {
    path: "/platforms/create",
    name: "PlatformCreate",
    component: PlatformCreate,
  },
  {
    path: "/platforms/:platformId",
    name: "PlatformEdit",
    component: PlatformEdit,
  },
  // platforms - ratings
  {
    path: "/platforms/:platformId/ratings",
    name: "PlatformRatings",
    component: PlatformRatings,
  },
  // platforms - investments
  {
    path: "/platforms/:platformId/investments",
    name: "PlatformInvestments",
    component: PlatformInvestments,
  },
  // platforms - statistics
  {
    path: "/platforms/:platformId/statistics",
    name: "PlatformStatistics",
    component: PlatformStatistics,
  },
  {
    path: "/platforms/:platformId/statistics/create",
    name: "PlatformStatisticsCreate",
    component: PlatformStatisticsCreate,
  },
  {
    path: "/platforms/:platformId/statistics/:statisticsId",
    name: "PlatformStatisticsEdit",
    component: PlatformStatisticsEdit,
  },
  // platforms - features
  {
    path: "/platforms/:platformId/features",
    name: "PlatformFeatures",
    component: PlatformFeatures,
  },
  {
    path: "/platforms/:platformId/features/create",
    name: "PlatformFeaturesCreate",
    component: PlatformFeaturesCreate,
  },
  {
    path: "/platforms/:platformId/features/:featureId",
    name: "PlatformFeaturesEdit",
    component: PlatformFeaturesEdit,
  },
  // platforms - assets
  {
    path: "/platforms/:platformId/assets",
    name: "PlatformAssets",
    component: PlatformAssets,
  },
  // platforms - publisher settings
  {
    path: "/platforms/:platformId/publisher-settings",
    name: "PlatformPublisherSettings",
    component: PlatformPublisherSettings,
  },
  // platforms - API
  {
    path: "/platforms/:platformId/api",
    name: "PlatformApi",
    component: PlatformApi,
  },
  // Publishers
  {
    path: "/publishers",
    name: "Publishers",
    component: Publishers,
  },
  // Data
  {
    path: "/data",
    name: "Data",
    component: Data,
  },
  // Telegram Bot
  {
    path: "/telegram-bot",
    name: "Telegram Bot",
    component: TelegramBot,
  },
  // Sniper
  {
    path: "/sniper",
    name: "Sniper",
    component: Sniper,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
