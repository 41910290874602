import store from "@/store";
import { Action } from "@/store/actions";

export let whenAppIsInitialized = new Promise(() => {
  // wait
});

export async function initializeApp(): Promise<any> {
  return (whenAppIsInitialized = Promise.all([
    store.dispatch(Action.APP_LOADS_CONSTANTS),
    store.dispatch(Action.APP_LOADS_PLATFORMS_MAP),
  ]));
}
