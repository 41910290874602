
import { Vue, Component, Prop } from "vue-property-decorator";
import { NOT_ASKED, LOADING, ERROR, SUCCESS, WebData } from "@/api/web-data";

@Component
export default class LoadingIndicator<E, D> extends Vue {
  NOT_ASKED = NOT_ASKED;
  LOADING = LOADING;
  ERROR = ERROR;
  SUCCESS = SUCCESS;

  @Prop() data: WebData<E, D>;
}
