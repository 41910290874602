import Keycloak, { KeycloakOnLoad } from "keycloak-js";
import {
  clearAuthHeader,
  getAccessTokenFromStorage,
  getRefreshTokenFromStorage,
  removeTokenFromStorage,
  setAuthHeader,
  setTokenInStorage,
} from "@/service/auth";
import { client as adminApiClient } from "@/api/admin-api";

const KEYCLOAK_CONFIG = {
  url: process.env.VUE_APP_KEYCLOAK_SERVER_URL,
  realm: "beyondp2p",
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
};

const INIT_OPTIONS = {
  onLoad: "login-required" as KeycloakOnLoad,
  // note: we need to deactivate the session status iframe here. Otherwise, we would have problems to set keycloak
  // cookies during the login process. Error message: "Cookie not found. Please make sure cookies are enabled in your
  // browser." See also:
  // * https://stackoverflow.com/q/60622192/3757139
  // * https://issues.redhat.com/browse/KEYCLOAK-12125
  // * https://github.com/keycloak/keycloak-documentation/blob/main/securing_apps/topics/oidc/javascript-adapter.adoc#session-status-iframe
  checkLoginIframe: false,
};

/**
 * If the token is about to expire within the next 60 seconds, we are going to refresh.
 */
const MIN_VALIDITY_SECONDS = 60;

const keycloak = new Keycloak(KEYCLOAK_CONFIG);

const KEYCLOAK_ADMIN_ROLE = "admin";

export async function authenticateWithKeycloak(): Promise<void> {
  keycloak.onTokenExpired = renewAccessToken;

  return keycloak
    .init({
      ...INIT_OPTIONS,
      token: getAccessTokenFromStorage(),
      refreshToken: getRefreshTokenFromStorage(),
    })
    .catch((error) => {
      console.error("Keycloak responded with error", error);
      clearAuthHeader(adminApiClient);
      removeTokenFromStorage();
      throw new Error(`Authenticated failed.`);
    })
    .then((auth) => {
      console.debug("`auth` value on init", auth);
      if (!auth) {
        clearAuthHeader(adminApiClient);
        removeTokenFromStorage();

        window.location.reload();
        return;
      }

      const roles = keycloak.realmAccess.roles;

      if (!roles.some((role) => role === KEYCLOAK_ADMIN_ROLE)) {
        throw new Error(
          `User could not be authorized with role "${KEYCLOAK_ADMIN_ROLE}" – aborting`
        );
      }
      storeToken(keycloak.token, keycloak.refreshToken);

      console.debug("Successfully authenticated");
    })
    .catch((error) => {
      logoutFromKeycloak();
      throw error;
    });
}

function storeToken(accessToken: string, refreshToken: string) {
  setAuthHeader(adminApiClient, accessToken);
  setTokenInStorage(accessToken, refreshToken);
}

export async function renewAccessToken(): Promise<void> {
  return keycloak
    .updateToken(MIN_VALIDITY_SECONDS)
    .then((refreshed) => {
      if (refreshed) {
        storeToken(keycloak.token, keycloak.refreshToken);
        console.log(`AccessToken has been refreshed. [refreshed=${refreshed}]`);
      } else {
        console.debug(
          `AccessToken has not been refreshed because it is still valid. [validFor=${Math.round(
            keycloak.tokenParsed.exp +
              keycloak.timeSkew -
              new Date().getTime() / 1000
          )} seconds]`
        );
      }
    })
    .catch((error) => {
      console.error("Keycloak responded with error", error);
      throw new Error("Failed to refresh token");
    });
}

export async function logoutFromKeycloak(): Promise<void> {
  return keycloak.logout().finally(() => {
    clearAuthHeader(adminApiClient);
    removeTokenFromStorage();
  });
}
