
import { Component, Vue } from "vue-property-decorator";
import {
  sendMessageToBotSubscribers,
  SubscriberFilter,
  SubscriberFilterEnum,
  SubscriberFilterPayload,
  TelegramBotAlert,
} from "@/api/admin-api";
import { AxiosError } from "axios";

interface FormData {
  message: string;
  publishToProdBot: boolean;
  showUpvoteButton: boolean;
  subscriberFilter: SubscriberFilter;
  subscriberFilterPayload: SubscriberFilterPayload;
}

@Component
export default class TelegramBotMessageForm extends Vue {
  SUBSCRIBER_FILTERS_ENUM = SubscriberFilterEnum;
  show = true;
  isSubmitting = false;
  isFormControlsDisabled = true;
  formData: FormData = TelegramBotMessageForm.emptyFormData();

  readonly telegramBotApiMaxMessageLength = 4096;

  get subscriberFilters(): SubscriberFilter[] {
    return this.$store.getters.getConstants.subscriberFilters.slice();
  }

  get telegramBotAlerts(): TelegramBotAlert[] {
    return this.$store.getters.getConstants.telegramBotAlerts.slice();
  }

  get shouldWarnForLongMessage(): boolean {
    return (
      this.formData.message.length >=
        this.telegramBotApiMaxMessageLength - 25 &&
      this.formData.message.length < this.telegramBotApiMaxMessageLength
    );
  }

  get shouldShowDangerForLongMessage(): boolean {
    return this.formData.message.length >= this.telegramBotApiMaxMessageLength;
  }

  get envToText(): string {
    if (process.env.VUE_APP_BEYONDP2P_ENVIRONMENT === "prod") {
      return "PROD";
    } else if (process.env.VUE_APP_BEYONDP2P_ENVIRONMENT === "staging") {
      return "STAGING";
    } else if (process.env.VUE_APP_BEYONDP2P_ENVIRONMENT === "dev") {
      return "DEV";
    } else {
      return `unknown environment (${process.env.BEYONDP2P_ENVIRONMENT})`;
    }
  }

  onSubmit(): void {
    const {
      message,
      subscriberFilter,
      subscriberFilterPayload,
      showUpvoteButton,
      publishToProdBot,
    } = this.formData;

    this.isSubmitting = true;
    // We preemptively disable form controls here. We want to avoid that the users submits the same message
    // twice without thinking about it. The user just has to change the input to re-enable the form controls.
    this.isFormControlsDisabled = true;

    sendMessageToBotSubscribers({
      message,
      subscriberFilter,
      subscriberFilterPayload,
      showUpvoteButton,
      publishToProdBot,
    })
      .then(this.submitSucceeded)
      .catch(this.submitFailed)
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  private submitSucceeded() {
    this.$noty.success(
      this.formData.publishToProdBot
        ? `The message has been published to the ${this.envToText} bot.`
        : "The message has been published to the DEV channel."
    );
  }

  private submitFailed(error: AxiosError) {
    this.$noty.error(
      this.formData.publishToProdBot
        ? `Failed to publish message to the ${this.envToText} bot.`
        : "Failed to publish message to the DEV channel."
    );
    console.error(error);
  }

  onChange(): void {
    this.isFormControlsDisabled = false;
  }

  onReset(): void {
    this.formData = TelegramBotMessageForm.emptyFormData();
    this.isFormControlsDisabled = true;

    // Trick to reset/clear native browser form validation state
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  private static emptyFormData(): FormData {
    return {
      message: "",
      publishToProdBot: false,
      showUpvoteButton: true,
      subscriberFilter: SubscriberFilterEnum.ADMINS_ONLY,
      subscriberFilterPayload: {},
    };
  }
}
