import { render, staticRenderFns } from "./SniperBotMessageForm.vue?vue&type=template&id=8f4799ce&scoped=true"
import script from "./SniperBotMessageForm.vue?vue&type=script&lang=ts"
export * from "./SniperBotMessageForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f4799ce",
  null
  
)

export default component.exports