import { State } from "@/store/index";
import { ApiConstants, PlatformsMap } from "@/api/admin-api";

export enum Mutation {
  SERVER_LOGGED_IN_USER = "SERVER_LOGGED_IN_USER",
  SERVER_FAILED_TO_LOGIN_USER = "SERVER_FAILED_TO_LOGIN_USER",
  SERVER_SENT_CONSTANTS = "SERVER_SENT_CONSTANTS",
  SERVER_SENT_PLATFORMS_MAP = "SERVER_SENT_PLATFORMS_MAP",
  USER_LOGGED_OUT = "USER_LOGGED_OUT",
}

export default {
  [Mutation.SERVER_LOGGED_IN_USER](state: State, newAccessToken: string) {
    state.accessToken = newAccessToken;
  },
  [Mutation.SERVER_FAILED_TO_LOGIN_USER](state: State) {
    state.accessToken = null;
  },
  [Mutation.SERVER_SENT_CONSTANTS](state: State, newConstants: ApiConstants) {
    state.constants = newConstants;
  },
  [Mutation.SERVER_SENT_PLATFORMS_MAP](
    state: State,
    newPlatformsMap: PlatformsMap
  ) {
    state.platformsMap = newPlatformsMap;
  },
  [Mutation.USER_LOGGED_OUT](state: State) {
    state.accessToken = null;
  },
};
