
import { Component, Vue } from "vue-property-decorator";
import {
  createPlatformClient,
  ExistsClientForPlatform,
  existsClientForPlatform,
  PlatformId,
} from "@/api/admin-api";
import PlatformsEditNav from "@/components/platforms/PlatformsEditNav.vue";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { AxiosError } from "axios";

interface FormData {
  email: string;
  baseUrl: string;
}

@Component({
  components: { PlatformsEditNav, LoadingIndicator },
})
export default class PlatformApi extends Vue {
  apiClientData: WebData<AxiosError, ExistsClientForPlatform> = webNotAsked();
  formData: FormData = this.emptyFormData();

  isFormControlsDisabled = false;

  get platformId(): PlatformId {
    return this.$route.params.platformId;
  }

  get platformName(): string {
    return this.$store.getters.lookupPlatformById(this.platformId)?.name;
  }

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.apiClientData = webLoading();
    return existsClientForPlatform(this.platformId)
      .then(this.loadingSuccess)
      .catch(this.loadingError);
  }

  private loadingSuccess(response: ExistsClientForPlatform) {
    this.apiClientData = webSuccess(response);
  }

  private loadingError(error: AxiosError) {
    this.apiClientData = webError(error);
  }

  async userSubmittedApiAccessCreateForm() {
    if (this.formData.email == null || this.formData.baseUrl == null) {
      this.$noty.error("E-Mail and Base URL must be set.");
      return;
    }

    try {
      this.isFormControlsDisabled = true;
      await createPlatformClient(this.platformId, this.formData);
      this.apiClientData = webSuccess({ existsClientForPlatform: true });
      this.$noty.success("Successfully created Platforms API client");
    } catch (error) {
      this.apiClientData = webError(error);
      this.$noty.error("Failure to create Platforms API client");
    } finally {
      this.isFormControlsDisabled = false;
    }
  }

  private emptyFormData(): FormData {
    return {
      email: "",
      baseUrl: "",
    };
  }
}
