import { AxiosInstance } from "axios";

const ACCESS_TOKEN_STORAGE_KEY = "vue-access-token";
const REFRESH_TOKEN_STORAGE_KEY = "vue-refresh-token";
const storage = sessionStorage;

export function setTokenInStorage(
  accessToken: string,
  refreshToken: string
): void {
  storage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
}

export function removeTokenFromStorage(): void {
  storage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  storage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
}

export function getAccessTokenFromStorage(): string | null {
  try {
    return storage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  } catch (e) {
    // swallow intentionally
    return null;
  }
}

export function getRefreshTokenFromStorage(): string | null {
  try {
    return storage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  } catch (e) {
    // swallow intentionally
    return null;
  }
}

export function setAuthHeader(
  client: AxiosInstance,
  accessToken: string
): void {
  client.defaults.headers.common["Authorization"] =
    formatAuthorizationHeaderWithBearerToken(accessToken);
}

export function formatAuthorizationHeaderWithBearerToken(
  accessToken: string
): string {
  if (accessToken == null || accessToken.trim() === "") {
    throw new Error(
      "Access token input cannot be empty when formatting authorization header"
    );
  }
  return `Bearer ${accessToken}`;
}

export function clearAuthHeader(client: AxiosInstance): void {
  client.defaults.headers.common["Authorization"] = null;
}
