
import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { initializeApp } from "@/service/app-init-service";

@Component({ components: { Navbar, Sidebar } })
export default class DashboardLayout extends Vue {
  created(): void {
    initializeApp();
  }
}
